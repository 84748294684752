<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col>
        <div>
          <b-button variant="success" @click="exportAll" v-if="$can('export_army_point_transaction')">Export</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Search :"
           label-for="search"
           description="Searchable: Name, Email, Referral Code"
          >
            <b-input-group>
              <b-form-input
               id="search"
               type="search"
               v-model="filterText"
               placeholder="Name, Email, Referral Code"
               @keypress="doFilter"
               @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                 variant="secondary"
                 type="button"
                 @click="resetFilter"
                >
                  reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Date :"
           label-for="dateRange"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="dateRange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Search By Title"
           label-for="title-status"
          >
            <b-form-select id="title-status" :options="titleOptions" v-model="title" @change="doFilterTitle">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
     ref="vuetable"
     @vuetable:load-error="handleLoadError"
     :api-url="apiUrl"
     :http-options="HttpOptions"
     :fields="fields"
     pagination-path=""
     :muti-sort="true"
     :sort-order="sortOrder"
     :append-params="moreParams"
     @vuetable:pagination-data="onPaginationData"
    >
     <template slot="date-slot" slot-scope="prop">
      <span>{{ prop.rowData.transaction_date }}</span>
     </template>
     <template slot="user-slot" slot-scope="prop">
      <router-link :to="onAction('view-user', prop.rowData.users.id, prop.rowIndex)" target="_blank">{{ prop.rowData.users.name }} ({{ prop.rowData.users.email }})</router-link>
     </template>
     <template slot="title-slot" slot-scope="prop">
      <span>{{ prop.rowData.title }}</span>
     </template>
     <template slot="type-slot" slot-scope="prop">
      <span>{{ prop.rowData.transaction_type }}</span>
     </template>
     <template slot="goldAmount-slot" slot-scope="prop">
      <span>{{ prop.rowData.gold_amount }}</span>
     </template>
     <template slot="idrAmount-slot" slot-scope="prop">
      <span>{{ prop.rowData.gold_currency }}</span>
     </template>
     <template slot="benefit-slot" slot-scope="prop">
      <span>{{ prop.rowData.benefit }}</span>
     </template>
     <template slot="points-slot" slot-scope="prop">
      <span>{{ prop.rowData.point }}</span>
     </template>
     <template slot="troops-slot" slot-scope="prop">
      <router-link :to="onAction('view-user', prop.rowData.troops.id, prop.rowIndex)" target="_blank">{{ prop.rowData.troops.name }} ({{ prop.rowData.troops.email }})</router-link>
     </template>
     <template slot="gen-slot" slot-scope="prop">
      <span>{{ prop.rowData.gen_troops }}</span>
     </template>
     <template slot="actions-slot" slot-scope="prop">
      <div class="custom-actions">
        <button class="btn btn-success" v-if="$can('detail_army_point_transaction')" @click="onAction('detail-item', prop.rowData)">Detail</button>
      </div>
     </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

let startDate = '';
let endDate = '';
Vue.use(Vuetable)

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data () {
    return {
      startDate: '',
      endDate: '',
      title: '',
      filterText: '',
      isLoading: false,
      titleOptions: [
        {value:'', text: 'Choose Title'},
        { value: 0, text: 'Civillian' },
        { value: 1, text: 'Knight' },
        { value: 2, text: 'Knight Lieutenant' },
        { value: 3, text: 'Knight Captain' },
        { value: 4, text: 'Knight Commander' }
      ],
      errors: {
        code: '',
        message: '',
        status: ''
      },
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `army/transaction-point`,
      HttpOptions:{
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'transaction_date',
          sortField: 'transaction_date',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        {
          name: 'date-slot',
          sortField: 'transaction_date',
          title: 'Transaction Date'
        },
        {
          name: 'user-slot',
          sortField: 'users.name',
          title: 'Penerima Benefit'
        },
        {
          name: 'title-slot',
          sortField: 'title',
          title: 'Title Penerima Benefit'
        },
        {
          name: 'type-slot',
          sortField: 'transaction_type',
          title: 'Transaction Type'
        },
        {
          name: 'goldAmount-slot',
          title: 'Gold Amount'
        },
        {
          name: 'idrAmount-slot',
          title: 'IDR Amount'
        },
        {
          name: 'benefit-slot',
          sortField: 'benefit',
          title: 'Benefit'
        },
        {
          name: 'points-slot',
          title: 'Points'
        },
        {
          name: 'troops-slot',
          sortField: 'troops.name',
          title: 'Transaksi User'
        },
        {
          name: 'gen-slot',
          sortField: 'gen_troops',
          title: 'Gen Transaksi User'
        },
        {
          name: 'actions-slot',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        }
      ]
    }
  },
  methods: {
    onAction (action, data) {
      if (action == 'detail-item') {
        this.$router.push({ name: 'Point Transaction Detail', params: { id: data.transaction_id } })
      }else if (action == 'view-user') {
        return { name: 'Detail End User', params: { id: btoa(data)} }
      }
    },
    doFilter () {
      this.$events.$emit('filter-set', this.filterText)
    },
    doFilterTitle () {
      this.$events.$emit('title-set', this.title)
    },
    resetFilter () {
      this.filterText = ''
      this.title = ''
      this.$events.$emit('filter-reset')
    },
    resetTitleFilter () {
      this.title = ''
      this.$events.$emit('title-reset')
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() => {
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    resetDateFilter () {
      this.startDate = ''
      this.endDate = ''
      this.title = ''
      this.$events.$emit('date-filter-reset')
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onFilterSet (filterText) {
      this.moreParams = {
        'search' : encodeURIComponent(filterText),
        'title': this.title,
        'start_from': this.startDate,
        'end_to': this.endDate
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onTitleFilterSet() {
      if (this.title === '') {
        this.onFilterReset()
      }else{
        this.moreParams = {
          'title': this.title,
          'search': encodeURIComponent(this.filterText),
          'start_from': this.startDate,
          'end_to': this.endDate
        }
        Vue.nextTick(() => this.$refs.vuetable.refresh())
      }
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire('date-set', [this.startdate, this.endDate])
    },
    onDateSet() {
      this.moreParams = {
        'search': encodeURIComponent(this.filterText),
        'title': this.title,
        'start_from': this.startDate,
        'end_to': this.endDate
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onClickClik() {
      const order = this.$refs.vuetable.sortOrder[0].direction
      const fields = this.$refs.vuetable.sortOrder[0].sortField

      const sortOrder = fields + '|' + order
      console.log(sortOrder)
    },
    onDateFiltereset () {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    exportAll() {
      this.isLoading = true
      const direction = this.$refs.vuetable.sortOrder[0].direction
      const field = this.$refs.vuetable.sortOrder[0].sortField
      const sortField = field + '|' + direction
      if (this.startDate != '' && this.endDate != '') {
        startDate = this.startDate;
        endDate = this.endDate;
      }else{
        endDate = this.$setDate.format('YYYY-MM-DD');
        startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        this.startDate = startDate;
        this.endDate = endDate;
      }
      this.$http.get('transaction/army-point?search=' + encodeURIComponent(this.filterText) + '&title=' + this.title + '&sort=' + sortField + '&start_from=' + startDate + '&end_to=' + endDate)
      .then((result) =>{
        this.isLoading = false
        const exportPath = result.data.meta.data;
        window.location.href = exportPath
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
        }
      })
     }
  },
  mounted() {
    this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
    this.$events.$on('date-set', eventData => this.onDateSet(eventData))
    this.$events.$on('title-set', eventData => this.onTitleFilterSet(eventData))
    this.$events.$on('filter-reset', () => this.onFilterReset())
    this.$events.$on('date-filter-reset', () => this.onDateFiltereset())
  }
}
</script>

<style>
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin:auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src='spinkit/scss/spinkit.scss' lang='scss'></style>
